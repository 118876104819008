import { errorHandler } from "../common";
import Api from "../api";
import cleanObject from "../../common/functions/cleanObject";
const { axios: api } = Api;
const queryString = require("query-string");

export const sendOrdersByParcelManager = (payload) =>
  errorHandler(async () => {
    const data = await api.post("pracel-manager/send_order", { payload });
    return { data: data.data };
  });

export const getParcelsByProvider = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `pracel-manager/info/?${queryString.stringify(cleanObject(payload), {
        arrayFormat: "bracket",
      })}`
    );
    return { data: data.data };
  });

export const getLabelsByProvider = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`pracel-manager/generate-labels`, payload);
    return { data: data.data };
  });
